<div class="Product-Promo">
  <div [style.background]="backgroundColor" class="Background-Box">
    <p [style.color]="textColor">{{ promoType }}</p>
    <h4 [innerHtml]="promoTitle" [style.color]="textColor"></h4>
    <button [style.color]="textColor">
      {{ promoAction }}
      <svg
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          [style.fill]="textColor"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.9917 16.9416C12.9071 16.8268 12.8399 16.6905 12.7941 16.5403C12.7483 16.3902 12.7247 16.2292 12.7247 16.0667C12.7247 15.9042 12.7483 15.7432 12.7941 15.5931C12.8399 15.4429 12.9071 15.3066 12.9917 15.1918L17.8041 8.65202L12.9917 2.11227C12.821 1.88023 12.7251 1.5655 12.7251 1.23734C12.7251 0.909178 12.821 0.594454 12.9917 0.362408C13.1624 0.130362 13.3939 -3.97696e-08 13.6353 -5.4114e-08C13.8767 -6.84584e-08 14.1082 0.130361 14.2789 0.362408L19.733 7.77709C19.8177 7.89188 19.8848 8.02825 19.9306 8.17839C19.9765 8.32852 20.0001 8.48947 20.0001 8.65202C20.0001 8.81457 19.9765 8.97552 19.9306 9.12565C19.8848 9.27579 19.8177 9.41216 19.733 9.52695L14.2789 16.9416C14.1945 17.0567 14.0941 17.148 13.9837 17.2103C13.8733 17.2726 13.7549 17.3047 13.6353 17.3047C13.5157 17.3047 13.3974 17.2726 13.2869 17.2103C13.1765 17.148 13.0762 17.0567 12.9917 16.9416Z"
        />
        <path
          [style.fill]="textColor"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M-2.75852e-08 8.65234C-3.49012e-08 8.32458 0.0957717 8.01024 0.266246 7.77847C0.436721 7.54671 0.667934 7.41651 0.909021 7.41651L17.2714 7.4165C17.5125 7.4165 17.7437 7.54671 17.9142 7.77847C18.0846 8.01024 18.1804 8.32458 18.1804 8.65234C18.1804 8.98011 18.0847 9.29445 17.9142 9.52621C17.7437 9.75798 17.5125 9.88818 17.2714 9.88818L0.909021 9.88818C0.667934 9.88818 0.436721 9.75798 0.266246 9.52621C0.0957717 9.29445 -2.02691e-08 8.98011 -2.75852e-08 8.65234Z"
        />
      </svg>
    </button>
  </div>
  <img src="{{ bucketUrl + promoImage }}" alt="" />
</div>
