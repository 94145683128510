import { Injectable,Inject, Optional } from '@angular/core';
import {  GaGlobalSettingType ,
          GaGlobalSettingValue,
          GaStreamSetting
        } from './google-analytics.interface';

/**
 * Config 
 */
 export class GaConfig {
  /**
   * GA4 Measurement Id (If omitted, set as NgxGa4Service.initalize$())
   */
  measurementId?: string;
}


declare global {
  interface Window {
    dataLayer : any;
  }
}

@Injectable({ providedIn: 'root'})
export class GoogleAnalyticsService {
  private measurementId? : string;

  constructor(
    @Optional() gaConfig? : GaConfig ) {
    if (gaConfig) {
      this.measurementId = gaConfig.measurementId;
    }
  }

  /**
   * Google Analytics script load
   * @param measurementId Measurement Id()
   * @returns 
   */
  install$ (measurementId? : string): Promise<void> {
    return new Promise((resolve, reject) => {
      if (measurementId) {
        this.measurementId = measurementId;
      }
      if (this.measurementId == null) {
        reject(`Measurement ID not set`);
      }
      
      const scriptId = `ga-gtag`;
      if (document.getElementById(scriptId)) {
        reject(new Error(`script id ${scriptId} is already loaded`));
      }
      const { head } = document;
      const script = document.createElement("script");
      script.id = scriptId;
      script.type = "text/javascript";
      script.async = true;
      script.onerror = () => {
        reject(`GA4 script load error`);
      }
      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        console.log('script loaded')
        resolve();
      }
      script.src = `https://www.googletagmanager.com/gtag/js?id=${this.measurementId}`;
      head.insertBefore(script, head.firstChild);
    });
  }

  /**
   * gtag naitive call
   * @param arg
   */
  gtag (...arg : any) : void {
    if (window.dataLayer) {
      window.dataLayer.push(arguments);
    }
    else {
      throw new Error(`Google Analytics Service not initalized.`);
    }
  }

  /**
   * gtag("js", date)
   * @param date date
   */
  js (date : Date = new Date()) : void {
    this.gtag("js" , date);
  }

  /**
   * gtag("config", ... )
   * @param streamSetting 
   */
  config (streamSetting? : GaStreamSetting) : void {
    if (streamSetting == null) {
      this.gtag("config", this.measurementId);
    }
    else {
      this.gtag("config", this.measurementId , streamSetting);
    }
  }

  /**
   * gtag("event" , ... )
   * @param eventName     string event name 
   * @param eventParams?  object event parameter
   */
  event (eventName: string , eventParams?: object) : void {
    if (eventParams == null) {
      this.gtag("event", eventName);
    }
    else {
      this.gtag("event", eventName, eventParams);
    }
  }
  /**
   * gtag ("set" , ...)
   * @param config NgxGa4GlobalSettingType 
   * @param value NgxGa4GlobalSettingValue 
   */
  set ( config : GaGlobalSettingType, value : GaGlobalSettingValue) : void {
    this.gtag("set", config , value);
  }
}
